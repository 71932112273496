$tuscany: #ca9f90;
$desert-sand: #e2c2b3;
$almond: #e8dbcf;
$neutral-cotton: #f5f1ee;
$antique-brass: #ba9374;
$laurel-green: #ced0c4;
$neon-pink: #d54282;
$neon-blue: #2da0d3;
$whispering-red: #9b5948;
$paleSilver: #d3bfb2;
$error-red: #d70101;
$error-background: #ffe7e7;
$black: #000000;
$white: #ffffff;
$dusty-pink: #f1e8e2;
$brand-forrest: #404f38;
$forest: $brand-forrest;
$soft-rose: #ead5cb;
$pale-grey: #b8b6b4;
$neutral-smoke: #a3a2a0;
$xanadu: #747c6c;

$placeholder-text: #7a7a79;
$neutral-shadow: $placeholder-text;

$tost-success-bg: #e3e4dd;
$tost-success-text: #404f38;
$toast-button-text: #3a3a3a;

$soft-rose: #ead5cb;

$status-ok: #31ad5a;
$notification: #c8524d;
$stock-orange: #f27f0d;

$icon-bg: $desert-sand;
$selectable-icon-bg: $almond;
$button-color: $antique-brass;
$calender-icon-bg: $almond;
$background-color: $neutral-cotton;
